.root {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  background-color: rgba(22, 52, 96, 0.1);
  padding: 6px 0 6px 10px;
  border-radius: 2px;
}

.progress {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.text {
  font-size: 13px;
  color: #ffffff !important;
}

.div_value {
  text-align: center;
  min-width: 110px;
  padding: 0 8px;
}

.value {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff !important;
}

.root_time {
  flex: 1;
  display: flex;
  align-items: center;
  background-color: rgba(22, 52, 96, 0.1);
  padding: 6px 0 6px 12px;
  border-radius: 2px;
}

.time_text {
  flex: 1;
  word-wrap: break-word;
  font-size: 13px;
  color: #ffffff !important;
}

.time_value {
  width: 100px;
  text-align: center;
  align-items: flex-end;
}

.time_unit {
  word-wrap: break-word;
  font-size: 13px;
  color: #ffffff !important;
}
