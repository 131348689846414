@import '@vars';

.icon {
  margin-right: 6px;

  &:hover {
    font-size: 17px;
    position: relative;
    top: 2px;
    left: -2px;
    margin-right: 3px;
  }
}

.item {
  padding-left: 4px !important;
  border: 1px solid @slider-rail-background-color-hover;
  background-color: @descriptions-bg;
  cursor: pointer !important;

  :global(.ant-picker-input) input {
    font-size: 13px;
  }

  &:hover {
    background-color: #f2f2f2;
  }
}

.disabled {
  :global(.ant-picker-input) input {
    color: #c1c1c1 !important;
  }
}
.delayed_style {
  :global(.ant-picker-input) input {
    color: @primary-color !important;
  }
}
