@import '@vars';

.item {
  //@descriptions-bg
  border: 1px solid @slider-rail-background-color-hover;
}

.icon {
  margin-left: 8px;
  margin-right: 4px;
  font-size: 20px;
}

.whatsapp {
  color: #55a630 !important;
  font-size: 18px;
}

.padrao {
  color: #ff5333 !important;
}

.url {
  margin-left: 8px;
  margin-right: 4px;
}
