.collumn {
  display: flex !important;
  align-items: center;
  border-right: 1px solid rgba(106, 107, 108, 0.2);
  justify-content: center;
}

.new_collumn {
  display: flex !important;
  flex: 1;
  align-items: baseline;
  justify-content: center;
}

.fb_collumn {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.facebook {
  display: flex;
  align-items: center;
}

.facebook_logo {
  color: rgb(24, 119, 242);
  font-size: 24px;
  margin-right: 4px;
}

.facebook_title {
  font-size: 20px;
  font-weight: bold;
}
