.root {
  .title {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .leadtitle {
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 4px;
  }
}
