@import '@vars';

.icon {
  margin-right: 6px;
  transition: all 0.1s;

  &:hover {
    transform: scale(1.4);
    position: relative;
  }
}

.item {
  border: 1px solid @slider-rail-background-color-hover;
  background-color: @descriptions-bg;

  :global(.ant-picker-input) input {
    font-size: 13px;
  }
}

.disabled {
  :global(.ant-picker-input) input {
    color: #c1c1c1 !important;
  }
}
.delayed_style {
  :global(.ant-picker-input) input {
    color: @primary-color !important;
  }
}
