.searchBarView {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 12px;
  background-color: white;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);

  .timer {
    margin-right: 12px;
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }

  .upload_progress {
    margin-right: 12px;
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}
