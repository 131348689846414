.card {
  margin-bottom: 16px !important;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
}
