@import './antd.less';

* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #dddddd;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 4px;
  }
}

body {
  font-family: BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans';
  color: rgba(0, 0, 0, 0.64);

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: #aaaaaa;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    border-radius: 6px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
