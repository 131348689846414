.container {
  flex-direction: column;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .profilePhoto {
    display: flex;
    flex: 1;
    background-color: #272727;
    height: 40px;
    width: 40px;
    border-radius: 20px;
  }
}
.button_google {
  margin: 0;
}
