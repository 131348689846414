.root {
  flex-direction: column;
  padding: 10px;
}

.white_button {
  display: block;
  color: rgba(255, 255, 255, 0.87);
  text-align: end;
  margin-top: 12px;
  margin-bottom: 4px;

  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.title {
  display: flex;
  color: rgba(255, 255, 255, 0.87) !important;
  font-size: 16px;
  font-weight: bold;
}

.description {
  display: flex;
  color: rgba(255, 255, 255, 0.87) !important;
  margin-top: 6px;
}

.novidade {
  color: rgba(255, 255, 255, 0.87) !important;
}

.rocket {
  color: rgba(255, 255, 255, 0.87) !important;
  margin-right: 4px;
  padding-top: 2px;
}
