.container {
  flex-direction: column;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .profilePhoto {
    display: flex;
    flex: 1;
    background-color: #272727;
    height: 40px;
    width: 40px;
    border-radius: 20px;
  }
}

.button_facebook {
  //background-color: #1673ea !important;
  //border-color: #1673ea !important;
  //color: rgba(255, 255, 255, 0.87) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  cursor: pointer;

  // &:hover {
  //   background-color: #0e74fa !important;
  //   border-color: #6ca6fc !important;
  //   color: rgb(255, 255, 255) !important;
  // }
}
