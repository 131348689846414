@import '@vars';

.item {
  border: 1px solid @slider-rail-background-color-hover !important;
}

.blue_button {
  margin-left: 6px;
  background: @secondary-color !important;
  border: @secondary-color !important;
}
