.cells_list_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;

  .cell_name {
    margin-left: 6px;
  }
}
