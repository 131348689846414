.mask {
  padding: 0;
}

.orange {
  color: #ff5333 !important;
  margin-right: 14px !important;
}

.text {
  margin-left: 20px;
  margin-bottom: 20px;
}

.item {
  margin-bottom: 10px;
}
