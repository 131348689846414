@import '@vars';

.root {
  //height: 40%;
  min-height: 40%;
}

.item {
  margin-bottom: 10px;
}

.accounts_div {
  margin-top: 20px;
  margin-bottom: 32px;
}

.accounts {
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
}

.updates {
  font-size: 18px;
  font-weight: bold;
  margin-left: 0;
}

.button {
  background-color: rgb(181, 181, 181) !important;
  border: rgb(181, 181, 181) !important;

  &:hover {
    opacity: 0.7;
  }
}
