.account_link {
  cursor: pointer;
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
}

.activity_link {
  cursor: pointer;

  &:hover {
    color: #ff5333 !important;
    text-decoration: underline !important;
  }
}
