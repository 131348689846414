.root {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  flex-direction: column;
}

.percent_value {
  color: #ffffff;
  font-weight: bold;
  font-size: 36px;
}

.text_explanation {
  margin-top: 16px;
  color: #ffffff;
  text-align: center;
  font-size: 13px;
  font-weight: 300;
}
