.markdown {
  ul,
  ol {
    white-space: normal;
    padding-left: 30px;
  }

  hr {
    border: inset 1px;
  }

  blockquote {
    display: block;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: 12px;
    margin-inline-end: 12px;

    line-height: 0.6rem;
    padding: 0 0 0 12px;
    border-left: inset 6px;

    p {
      line-height: 1.2rem;
    }
  }

  pre,
  code {
    font-family: monospace, monospace;
    font-size: 1em;

    padding: 0.2rem 0.5rem;
    margin: 0 0.2rem;
    font-size: 90%;
    white-space: nowrap;
    background: #f1f1f1;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
  }

  pre {
    > code {
      all: unset;
      white-space: preserve;
    }
  }
}
