.root {
  height: 100%;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;
}

.divisor {
  font-size: 14px;
  font-weight: 400;
}

.title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.form {
  flex: 1;
  margin-left: 20px;
}

.div_avatar {
  display: flex;
  flex-direction: row;
}

.avatar {
  width: 160px;
  height: 160px;
  display: block;
  align-items: center;
  justify-content: center;
}
