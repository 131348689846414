@import '@vars';

.item_div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d9;
  border-radius: 4px;
  overflow: hidden;
  padding: 0;

  cursor: pointer;

  > p {
    color: @primary-color;
    padding: 0;
    margin: 0;
  }
}
