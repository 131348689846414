@import '@vars';

.root {
  height: 100%;
  align-self: center;
  justify-self: center;
  justify-content: center;

  .filters {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.blue_button {
  background: @secondary-color !important;
  border: @secondary-color !important;
  margin-top: 6px;
}

.buttons_view {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
}

.date_div {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 2px 0 0;
}

.activity {
  background-color: @primary-color;
  padding: 4px 0;
  margin-bottom: 4px;
  font-size: 12px;
}

:global(.ant-picker-calendar-date-today) {
  border: 2px solid @primary-color !important;
}

:global(.ant-picker-calendar-date) {
  height: 100% !important;
}

:global(.ant-picker-calendar-date-content) {
  height: 100% !important;
  //height: 140px !important;
}

:global(.ant-picker-calendar .ant-picker-cell) {
  height: 140px;
}

:global(.no-weekend .ant-picker-content > thead > tr > th:nth-child(6n + 1)),
:global(.no-weekend .ant-picker-content > tbody > tr > td:nth-child(6n + 1)) {
  display: none;
}
