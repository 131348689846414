@import '@vars';

.icon {
  margin-right: 6px;
}

.item {
  border: 1px solid @slider-rail-background-color-hover;
  background-color: @descriptions-bg;
}
