@import '@vars';

.root {
  flex-direction: column;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;
}

.blue_button {
  margin-left: 6px;
  background: @secondary-color !important;
  border: @secondary-color !important;
}

.buttons_view {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}
