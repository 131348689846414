@import '@vars';

.accounts {
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
}

.carousel {
  button {
    height: 15px !important;
    background-color: @primary-color !important;
  }
}

:global(.ant-carousel .slick-dots) {
  position: relative !important;
}

:global(.ant-carousel .slick-dots-bottom) {
  bottom: 0 !important;
}

.dot_button {
  height: 15px !important;
  background-color: @primary-color !important;
}
