.root {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 14px 18px;
}

.item {
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1;
}

.last_item {
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1;
}
