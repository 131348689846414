.text {
  margin-bottom: 20px;
}

.orange {
  color: #ff5333 !important;
  font-size: 14px;
}

.attention_text {
  font-size: 14px;
}

.item {
  margin-bottom: 10px;
}

.icon {
  margin-right: 10px;
}

.avatar_menu_gif {
  margin: 1em auto;
  border: 1px solid #000;
}

.image_div {
  display: flex;
  width: 100%;
  justify-content: center;
}
