.body {
  white-space: pre-wrap;

  &:hover {
    background-color: #c5bdbd63 !important;
  }
}

.title {
  font-size: 18px;
  font-weight: 700;
  white-space: pre-wrap;

  > span {
    font-size: 18px;
  }

  &:hover {
    background-color: #c5bdbd63 !important;
  }
}

.divisor {
  font-size: 16px;
  font-weight: 600;
}
.icon {
  margin-right: 6px;
}

.users_list_item {
  display: flex;
  flex-direction: row;

  .profile_photo {
    background-color: #272727;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    margin-right: 6px;
  }
}
