.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  font-size: 16px;
  color: #ffffff;
}

.help {
  margin-left: 12px;
}
