@import '@vars';

.title {
  //margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  //justify-content: space-between;
  border-bottom: 1px solid rgb(229, 229, 229);
  align-items: center;
}

.title_icon {
  margin-right: 4px;
  margin-bottom: -2px;
}

.title_text {
  padding-top: 8px;
  padding-left: 16px;
  font-size: 16px !important;
}

.help {
  margin-left: 10px;
  padding-right: 16px;
}

.activities_list {
  height: 450px;
  overflow-y: auto;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
    border-radius: 6px;
  }
}
