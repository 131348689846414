.menuItem {
  user-select: none;
  cursor: pointer;

  display: flex;
  align-items: center;
}

.see_all {
  justify-content: center !important;
  padding: 4px 8px !important;
  font-size: 12px !important;

  &:hover {
    text-decoration: underline;
    color: #ff5333;
  }

  cursor: pointer;
}

.read {
  justify-content: center !important;
  padding: 4px 8px !important;
  font-size: 12px !important;
}

.fireworks_image {
  filter: contrast(0.2);
}
