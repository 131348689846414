.feedbacksDivisor {
  margin-bottom: 16px;
  margin-right: 4px;
  font-size: 16px;
  font-weight: 600;
}

.title {
  display: flex;
  align-items: baseline;
}

.body {
  white-space: pre-wrap;
}

.name {
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.date {
  font-size: 10px;
}

.profilePhoto {
  display: flex;
  flex: 1;
  background-color: #272727;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin-right: 12px;
}
