.root {
  margin-bottom: 16px !important;
  border-color: #ff5333 !important;

  .name {
    font-size: 16px;
  }

  .email {
    font-size: 12px;
  }

  .profilePhoto {
    display: flex;
    flex: 1;
    margin-right: 12px;
  }
}
