.classificationcontainer {
  margin: 0 auto !important;
  width: 100%;
}

.classificationcontent {
  align-items: center;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50px;
  width: 100%;

  span {
    font-size: 11px;
    font-weight: 500;
  }

  .classificationtype {
    align-items: center;
    display: flex;
    font-size: 14px;

    span {
      font-size: 15px;
      padding-right: 5px;
      font-weight: bold;
    }
  }
}