@import '@vars';

.root {
  height: 100%;

  .body {
    white-space: pre-wrap;
  }

  .title {
    font-size: 18px;
    font-weight: 700;
    margin: 0;

    > span {
      font-size: 18px;
    }
  }

  .divisor {
    font-size: 16px;
    font-weight: 600;
  }
}
