.mask {
  padding: 0;
}

.text {
  margin-bottom: 20px;
}

.orange {
  color: #ff5333 !important;
  font-size: 14px;
}

.attention_text {
  font-size: 14px;
}
