.notFound {
  font-weight: bold;
}
.server {
  display: flex;
  flex: 1;
  font-weight: bold;
  max-width: 400px;
}
.box {
  justify-content: center;
}
.paper {
  display: flex;
  align-items: center;
  justify-content: center;
}
