.root {
  flex-direction: column;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;

  .divisor {
    font-size: 18px;
    font-weight: 700;
  }

  .text {
    font-size: 16px;
    font-weight: 500;
  }
}
