.leadtitlecontainer {
  display: flex;
  width: 100%;
}

.text {
  display: flex;
  flex-direction: column;
}

.leadtitle {
  align-items: center !important;
  display: flex !important;
  font-size: 20px;

  & > span {
    margin-left: 4px;
  }
}

.leaddescription {
  font-size: 12px;
  vertical-align: top !important;

  & > span {
    margin-left: 4px;
  }
}

.deletecontainer {
  margin-left: auto;
  margin-right: 32px;
}
