.title {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgb(229, 229, 229);
  align-items: center;
}

.title_text {
  padding-top: 8px;
  padding-left: 16px;
  font-size: 16px !important;
  color: rgba(118, 131, 143, 1) !important;
}

.title_icon {
  align-items: center;
  margin-right: 4px;
  margin-bottom: -4px;
}

.ads_row {
  margin: 6px 10px 10px 16px;
}

.item {
  display: flex;
  height: 320px;
  overflow-y: auto;
  width: 100%;
  padding-right: 10px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
    border-radius: 4px;
  }
}
