@import '@vars';

.item {
  border: 1px solid @slider-rail-background-color-hover;
  background-color: @descriptions-bg;
}

.fireworks_image {
  filter: contrast(0.2);
}
