.welcome_user {
  flex: 1;
  align-items: center;
  padding: 16px 20px !important;
}

.mean_performance {
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicators {
  display: flex !important;
  align-content: center;
  background-color: rgba(105, 160, 242, 0.52) !important;
  min-height: 300px !important;
}

.goal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.help {
  margin-top: 24px;
  margin-left: 12px;
}
