.users_list_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .user_name {
    margin-left: 6px;
    flex: 1;
  }

  .user_name_tag {
    flex: 1;
  }
}
