@import '@vars';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.removeButton {
  text-align: center;
  margin-top: 8px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.uploadPicture {
  cursor: pointer;
  &:hover {
    filter: blur(2px);
    opacity: 0.5;
  }
}
