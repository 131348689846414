.progresscontainer {
  margin-bottom: 8px;
  height: 20px;
  display: flex;
  flex-direction: row;
  background-color: #00000040;
  border-radius: 4px;
  overflow: hidden;
}

.value {
  align-items: center;
  display: flex;
  color: #fff;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  padding: 2px 0;
}

.valuespercentage {
  text-align: center;
  font-size: 12px;
}
