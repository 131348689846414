@import '@vars';

.icon {
  margin-right: 6px;
}

.panel {
  padding: 0;
  //margin-bottom: 14px;
  background-color: @descriptions-bg;
}

.collapse {
  :global(.ant-collapse-header) {
    padding: 6px 10px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.title {
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  align-items: center;
}

.expand_icon {
  display: flex;
  padding: 10px 8px 11px;
  //overflow: hidden;
  color: @white87 !important;
}

.buttons_view {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.buttons_margin {
  margin-left: 6px;
  background: @secondary-color !important;
  border: @secondary-color !important;
}
