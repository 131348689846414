.item_logo {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.menuItem {
  display: flex;
  align-items: center;
}
