@import '@vars';

.filters {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.item {
  cursor: pointer;
}

.users_list_item {
  display: flex;
  flex-direction: row;
  align-items: center;

  .user_name {
    margin-left: 6px;
  }
}
