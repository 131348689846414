.info {
  margin-bottom: 8px;

  .infoicon {
    font-size: 16px;
    margin-right: 8px;
    padding: 0;
    height: 32px;
    width: 32px;
  }

  .infoname,
  .custominput {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-weight: 600;
  }
}
