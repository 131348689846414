.body {
  white-space: pre-wrap;

  &:hover {
    background-color: #f6f6f6 !important;
  }
}

.title {
  font-size: 18px;
  font-weight: 700;
  margin: 0;

  > span {
    font-size: 18px;
  }

  &:hover {
    background-color: #f6f6f6 !important;
  }
}

.form_item {
  margin: 20px 0;
}

.label {
  font-size: 16px;
  font-weight: 600;
  margin-right: 4px;
}

.divisor {
  font-size: 16px;
  font-weight: 600;
}
.icon {
  margin-right: 6px;
}

.column {
  display: inline-flex !important;
  align-items: baseline;
}
