.table {
  width: 100% !important;
}

.first_title {
  max-width: 25% !important;
  align-items: center;
  justify-content: flex-start;
  color: rgba(118, 131, 143, 1) !important;
  font-weight: 500;
}

.second_title {
  max-width: 25% !important;
  align-items: center;
  justify-content: flex-start;
  color: rgba(118, 131, 143, 1) !important;
  font-weight: 500;
}

.titles {
  width: 12.5% !important;
  text-align: center;
  color: rgba(118, 131, 143, 1) !important;
  font-weight: 500;
}

.first_line {
  height: 40px;
  border-bottom: 1px solid rgb(229, 229, 229);
  margin-bottom: 2px;
}

.image_picture {
  display: flex;
  justify-content: center;
  align-items: center;
}

.collumn {
  text-align: center;
  color: rgba(94, 105, 115, 1) !important;
  font-weight: 600;
}

.name {
  max-width: 0;

  //para browsers que não tem suporte ao webkit
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
}

//quebra a frase em 3 linhas e depois coloca ellipsis
//restrição: não funciona em opera mini
//em opera mini apenas quebra em várias linhas e o height será maior
.text_name {
  //@supports (-webkit-line-clamp: 3) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  //}
}

.line {
  height: 100px;
}
