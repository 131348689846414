.root {
  overflow: hidden;
  color: #ffffff;
  align-items: center;
}

.introduction {
  text-align: center;
  font-size: 24px;
  font-weight: 600;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.performance_text {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
