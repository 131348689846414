@import '@vars';

.root {
  height: 100%;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;

  .filters {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.date_div {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 2px 0 0;
  height: 100%;
}

.activity {
  color: rgba(255, 255, 255, 0.87);
  background-color: @primary-color;
  padding: 4px 4px 4px 0;
  height: 28px;
  margin-bottom: 4px;
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.85;
    font-size: 14px;
    padding: 2px 4px 2px 0;

    .activity_checkbox {
      margin-top: 2px;
      margin-bottom: 2px;
    }

    .activity_client_logo {
      margin-top: -1px;
      margin-bottom: 2px;
    }

    .icon {
      margin-top: 1px;
      margin-bottom: 1px;
    }
  }

  > div {
    display: inline;
  }

  .activity_checkbox {
    margin-top: 0;
    margin-bottom: 0;
  }

  .activity_client_logo {
    margin-top: -1px;
  }

  .icon {
    display: inline-block;
    margin: 0;
    margin-left: -6px !important;
    margin-right: -4px !important;
  }

  .task_title {
    font-size: 12px;
    display: block;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

:global(.ant-picker-calendar-date-today) {
  border: 2px solid @primary-color !important;
}

:global(.ant-picker-calendar-date) {
  background-color: transparent !important;

  &:hover {
    background-color: #fff5f0 !important;
    z-index: 1 !important;
  }
}

:global(.ant-picker-calendar-date) {
  height: 100% !important;
  background-color: transparent;
}

:global(.ant-picker-calendar-date-content) {
  height: 100% !important;
  //height: 140px !important;
}

:global(.ant-picker-calendar .ant-picker-cell) {
  height: 140px;
}
