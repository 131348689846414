@import '@vars';

.root {
  flex-direction: column;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;

  .filters {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .item {
    cursor: pointer;
  }

  .icon {
    margin-right: 6px;
  }
}
.blue_button {
  margin-left: 6px;
  background: @secondary-color !important;
  border: @secondary-color !important;
}
