.root {
  flex-direction: column;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;
}

.item {
  cursor: pointer;
}

.profilePhoto {
  display: flex;
  flex: 1;
  background-color: #272727;
  height: 40px;
  width: 40px;
  border-radius: 20px;
}

.form {
  flex: 1;
  margin-left: 20px;
}

.div_avatar {
  display: flex;
  flex-direction: row;
}

.avatar {
  width: 160px;
  height: 160px;
  display: block;
  align-items: center;
  justify-content: center;
}
