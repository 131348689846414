.divisor {
  display: flex;
  flex-direction: row;
  padding: 8px 0 6px;
  font-size: 16px;
  margin: 4px 0 4px;
  //border: 1px solid #ff5333;
  //border-top-right-radius: 50px;
  //background-color: #ff5333;
  //color: rgba(255, 255, 255, 0.87);
  cursor: pointer;
}
.collapse_timers {
  :global(.ant-collapse-header) {
    align-items: center !important;
    padding-top: 0 !important;
    padding-bottom: 6px !important;
  }
  :global(.ant-collapse-arrow) {
    height: 100%;
    display: flex !important;
    align-items: center !important;
    margin: -4px 0 0 !important;
    padding: 0 !important;
  }
}
