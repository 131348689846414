.feedbacksDivisor {
  margin-bottom: 16px;
  margin-right: 4px;
  font-size: 16px;
  font-weight: 600;
  color: #ff5333 !important;
}

.title {
  display: flex;
  align-items: baseline;
}
