.classificationcontainer {
  margin: 0 auto !important;
  width: 100%;
}

.classificationcontent {
  align-items: center;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80px;
  width: 100%;

  span {
    font-size: 12px;
    font-weight: 500;
    padding-top: 4px;
  }
}