@import '@vars';

.list {
  background-color: #fff;
  padding: 2;
  display: flex;
  flex: 1;
  flex-direction: column;

  .item {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 2px 4px;
    border-bottom: 1px solid @slider-rail-background-color-hover;
  }

  .close_icon {
    padding-top: 4px;
    margin-left: 6px;
  }

  .progress {
    display: flex;
    flex: 1;
    min-width: 200px;
  }

  .tooltip {
    display: flex;
    width: 100%;
  }
}
