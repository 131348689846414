@import '@vars';

.filters {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.item {
  cursor: pointer;
}

.icon {
  margin-right: 6px;
}

.blue_button {
  margin-left: 6px;
  background: @secondary-color !important;
  border: @secondary-color !important;
}

.users_list_item {
  display: flex;
  flex-direction: row;
  align-items: center;

  .user_name {
    margin-left: 6px;
    flex: 1;
  }
}
