.root {
  flex-direction: column;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;

  .item {
    cursor: pointer;
  }

  .icon {
    margin-right: 6px;
  }
}

.filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter_icons {
  margin: 0 0 -4px;
  padding: 0;
  font-size: 18px;
}
