@import '@vars';

.root {
  flex-direction: column;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;
}

.icon {
  margin-right: 6px;
}

.panel {
  padding: 0;
  //margin-bottom: 14px;
  background-color: @descriptions-bg;
}

.collapse {
  :global(.ant-collapse-header) {
    padding: 6px 10px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.div_title_global {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.title {
  margin-bottom: 16px !important;
  margin-right: 4px;
}

.buttons_fixed {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

.buttons_view {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.buttons_create {
  margin-left: 6px;
  background: @secondary-color !important;
  border: @secondary-color !important;
}

.buttons_delete {
  margin-left: 6px;
  background: @primary-color !important;
  border: @primary-color !important;
}

.button_back {
  background: @secondary-color !important;
  border: @secondary-color !important;
}

.div_title_account {
  display: flex;
  flex-direction: row;
}
