.body {
  white-space: pre-wrap;
}

.name {
  font-size: 16px;
}

.date {
  font-size: 10px;
}

.profilePhoto {
  display: flex;
  flex: 1;
  margin-right: 12px;
}
