.mask {
  padding: 0;
}

.avatar_menu_gif {
  margin: 1em auto;
  border: 1px solid #000;
}

.image_div {
  display: flex;
  width: 100%;
  justify-content: center;
}
