@import '@vars';

.item {
  //@descriptions-bg
  border: 1px solid #ccc !important;
  padding: 20px;
}

.icon {
  margin-left: 18px;
  margin-right: 6px;
  font-size: 20px;
}

.nexticon {
  font-size: 30px;
  margin-right: 10px;
}

.text {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  margin-right: 4px;
}

.icontext {
  align-items: center;
  display: flex !important;
  font-size: 12px;
  color: #777;

  & > span {
    margin-left: 4px;
  }
}
