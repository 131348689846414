.template {
  display: flex;
  flex-direction: column;
  padding: 6px 4px;
  margin-top: 4px;
  border-radius: 2px;
  background-color: #fafafa;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);

  cursor: pointer;

  transition: all 0.5s ease-out;

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 4px;
  }
}
