@import '@vars';

.root {
  flex-direction: column;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;

  .item {
    cursor: pointer;
  }
}

.icon {
  margin-right: 6px;
}
