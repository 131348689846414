.updates {
  font-weight: 600;
  padding: 8px 16px;
  font-size: 16px !important;
  margin-right: 8px;
}

.title {
  display: flex;
  flex-direction: row;
  //justify-content: space-between;
  border-bottom: 1px solid rgb(229, 229, 229);
  align-items: center;
}

.title_text {
  padding-top: 8px;
  padding-left: 16px;
  font-size: 16px !important;
}
