@import '@vars';

.ant-list {
  border-bottom: 1px solid @slider-rail-background-color-hover;
}

.ant-picker-calendar-date-content {
  &::-webkit-scrollbar {
    width: 4px;
    margin-left: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #a4a4a4;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb :hover {
    background-color: #c7c7c7;
  }
}

.ant-tabs-content-holder {
  padding-top: 16px;
  padding-right: 6px;
  height: calc(100vh - 30px);
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 6px;
  }
}

.ant-upload-picture-card-wrapper {
  display: flex !important;
  height: 100% !important;
}

.ant-upload-select-picture-card {
  display: flex;
  flex: 1;
  height: 100% !important;
  width: 100% !important;
  border-radius: 50% !important;
  margin: 0 !important;
  border: 0 !important;
}

.ant-collapse-arrow {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  margin: -8px 0 0 !important;
  padding: 0 !important;
}

.ant-spin-nested-loading {
  flex: 1;
}
