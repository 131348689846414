.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 4px 8px;
}

.title {
  font-size: 13px;
  color: rgba(118, 131, 143, 1) !important;
  padding-top: 10px !important;
}

.value {
  font-size: 24px;
  color: rgba(118, 131, 143, 1) !important;
  font-weight: 700;
}

.progress {
  display: flex;
  flex-direction: column;
}

.icon_up {
  font-size: 14px;
  color: rgba(98, 168, 234, 1) !important;
  margin-right: 2px;
}

.icon_down {
  font-size: 14px;
  color: rgba(249, 104, 104, 1) !important;
  margin-right: 2px;
}

.text {
  font-size: 10px;
  color: rgba(118, 131, 143, 1) !important;
  word-wrap: break-word !important;
}
