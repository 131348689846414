.root {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.item {
  font-size: 20px;
  font-weight: bold;
}

.icon {
  align-items: center;
  margin-left: 6px;
}

.arrow {
  flex: 1;
  color: rgb(92, 92, 92) !important;
  font-size: 16px;
  align-items: center;
}
