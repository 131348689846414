.text {
  margin-left: 20px;
  margin-bottom: 20px;
}

.item {
  margin-bottom: 10px;
}

.icon {
  margin-right: 10px;
}

.tour {
  padding-bottom: 10px;
}

.mask {
  padding: 0;
}
