.root {
  .body {
    margin: 20px 0;
  }

  .divisor {
    font-size: 16px;
    font-weight: 600;
  }

  .title {
    margin-bottom: 20px;
  }

  .label {
    font-size: 16px;
    font-weight: 600;
    margin-right: 4px;
  }

  .column {
    display: inline-flex !important;
    align-items: baseline;
  }
}

.bold {
  color: #ff5333;
}

.example {
  color: rgba(255, 255, 255, 0.87);
}
