.root {
  flex-direction: column;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;

  .item {
    cursor: pointer;

    .profilePhoto {
      display: flex;
      flex: 1;
      background-color: #272727;
      height: 40px;
      width: 40px;
      border-radius: 20px;
    }
  }
}
