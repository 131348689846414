.root {
  .content {
    height: calc(100vh - 66px);
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: #dddddd77;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #aaaaaa;
      border-radius: 6px;
    }
  }

  .side_menu {
    height: calc(100vh - 66px);
    //overflow-y: auto;
    //overflow-x: hidden;

    //&::-webkit-scrollbar {
    //  width: 8px;
    //}

    //&::-webkit-scrollbar-track {
    //  background-color: #dddddd77;
    //}

    //&::-webkit-scrollbar-thumb {
    //  background-color: #aaaaaa;
    //  outline: 1px solid #c7c7c7;
    //}
  }

  .button {
    background-color: rgb(181, 181, 181) !important;
    border: rgb(181, 181, 181) !important;

    &:hover {
      opacity: 0.7;
    }
  }
}
