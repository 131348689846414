.mask {
  padding: 0;
}

.gif {
  margin: 1em auto;
  border: 1px solid #000;
}

.image_div {
  display: flex;
  width: 100%;
  justify-content: center;
}

.text {
  //margin-left: 10px;
  margin-bottom: 20px;
}

.item {
  margin-bottom: 10px;
  margin-left: 20px;
}

.item_orange {
  color: #ff5333 !important;
  margin-right: 10px;
}

.icon {
  margin-right: 10px;
}

.orange {
  color: #ff5333 !important;
  margin-right: 14px;
  margin-left: 20px;
}

.black {
  color: rgba(0, 0, 0, 0.85) !important;
  margin-right: 10px;
}

.gray {
  color: #c1c1c1 !important;
  margin-right: 10px;
}

.attention_text {
  font-size: 14px;
}
