.title {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgb(229, 229, 229);
  align-items: center;
}

.title_text {
  padding-top: 8px;
  padding-left: 16px;
  font-size: 16px !important;
  color: rgba(118, 131, 143, 1) !important;
}

.title_icon {
  margin-right: 4px;
  margin-bottom: -2px;
  color: rgba(118, 131, 143, 1) !important;
}

.collumn {
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  border-right: 1px solid rgba(106, 107, 108, 0.2);
}

.line {
  border-bottom: 1px solid rgba(106, 107, 108, 0.2);
}

.last_collumn {
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
}

.row {
  display: flex !important;
  flex: 1;
  height: 90%;
}
