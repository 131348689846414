.body {
  white-space: pre-wrap;

  &:hover {
    background-color: #f6f6f6 !important;
  }
}

.title {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding-left: 0 !important;

  > span {
    font-size: 18px;
  }

  &:focus {
    padding-left: 11px !important;
  }

  &:hover {
    background-color: #f6f6f6 !important;
  }
}

.form_item {
  margin: 20px 0;
}

.label {
  font-size: 16px;
  font-weight: 600;
  margin-right: 4px;
}

.divisor {
  font-size: 16px;
  font-weight: 600;
}
.icon {
  margin-right: 6px;
}

.users_list_item {
  display: flex;
  flex-direction: row;

  .profile_photo {
    background-color: #272727;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    margin-right: 6px;
  }
}

.column {
  display: inline-flex !important;
  align-items: baseline;
}
