.textdate {
  margin-top: 10px;
  margin-bottom: 4px !important;
  font-weight: bold;
}

.filtercontainer {
  width: 100%;
}

.search {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.pageIndicator {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
