.body {
  white-space: pre-wrap;
}

.card {
  :global(.ant-card-head-title) {
    padding: 0 !important;
  }
}

.nps {
  display: flex;
  justify-content: center;
}

:global(.feedback) {
  padding-right: 6px;

  :global(.ant-card-meta-avatar) {
    margin-top: 6px;
  }
  :global(.ant-card-meta-title) {
    color: #000;
    margin-bottom: 0 !important;
    margin-top: 6px;
  }
  :global(.ant-card-meta-description) {
    font-size: 12px;
  }
}
