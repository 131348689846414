.side_menu {
  //height: calc(100vh - 66px - 242px - 60px);
  display: 'flex';
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 10px;
  }
}

.icon {
  margin-right: 18px;
  margin-bottom: -3px;
}

.account_icon {
  display: inline;
  margin-left: 0px;
  margin-right: 13px;
  width: 30px !important;
}

.menu_item {
  padding-left: 22px !important;
}

.account_item {
  padding-left: 16px !important;
}

:global(.ant-menu-inline-collapsed) {
  width: 62px !important;
}
