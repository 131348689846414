.title {
  display: flex;
  color: rgba(255, 255, 255, 0.87) !important;
  font-size: 16px;
  font-weight: bold;
}

.description {
  display: flex;
  color: rgba(255, 255, 255, 0.87) !important;
  margin-top: 6px;
}

.icon {
  font-size: 18px;
}

.text {
  color: rgba(255, 255, 255, 0.87) !important;
  margin-top: 6px;
  font-size: 14px;
}
